import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import Cross from "@icons/cross.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { orderDetailQuery, useEditOrderMutation } from "./orders.api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";
import { Spinner } from "@/common/components/spinner/spinner";
import { Failed } from "@/common/components/info/info";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { Button } from "@/common/components/button/button";
import { useRemoveOrderMutation } from "../shared/api/remove-order";
import { Dialog, useDialogProvider } from "@/common/components/dialog/dialog";
import { Fields, OrderForm } from "@/common/components/order-form/order-form";
import { path as successPath } from "../shared/routes/success/success";
import { Trans } from "@lingui/macro";
import { useContractSignGuard } from "@/common/acl/statutory-guard/contract-sign-guard";
import { handleFormError } from "../shared/api/error-handler";
import { path as failedPath } from "../shared/routes/failed/failed";

const path = "offers/:offerId/orders/:orderId";
const Order = () => {
  const [searchParams] = useSearchParams();
  const { offerId, orderId } = useParams();
  invariant(offerId);
  invariant(orderId);
  const order = useQuery(
    orderDetailQuery({
      offerId,
      orderId,
    }),
  );

  const queryClient = useQueryClient();
  const {
    closeDialog: closeConfirmationDialog,
    openDialog: openConfirmationDialog,
  } = useDialogProvider();
  const removeOrder = useRemoveOrderMutation();
  const editOrder = useEditOrderMutation();
  const navigate = useNavigate();
  const handleSubmit = (data: Fields) => {
    const existing = order.data;
    invariant(existing);

    editOrder.mutate(
      {
        id: orderId,
        order: {
          quarter: data.quarter,
          customTransport: data.customTransport,
          transportNote: data.customTransportNote,
          currency: order.data.order.currency,
          active: true,
          amount: data.tons,
          offer: offerId,
          price: data.pricePerTon,
          lowPrice: data.lowPricePerTon,
        },
      },
      {
        onError: (e) => {
          const errors = handleFormError(e);
          navigate(failedPath + "?" + searchParams.toString(), {
            state: { errors },
          });
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          navigate(successPath);
        },
      },
    );
  };
  const guardedSubmit = useContractSignGuard({ action: handleSubmit });

  const handleRemoveOrder = () => {
    closeConfirmationDialog();
    removeOrder.mutate(
      { orderId },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          navigate("..");
        },
      },
    );
  };

  const ConfirmationDialog = (
    <Dialog>
      <Dialog.Title>
        <Trans>Chcete příkaz opravdu smazat?</Trans>
      </Dialog.Title>
      <Dialog.Actions>
        <Dialog.Confirm onClick={handleRemoveOrder}>
          <Trans>Ano</Trans>
        </Dialog.Confirm>
        <Dialog.Cancel onClick={closeConfirmationDialog}>
          <Trans>Ne</Trans>
        </Dialog.Cancel>
      </Dialog.Actions>
    </Dialog>
  );

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to="..">
            <Cross />
          </SearchParamsLink>
        }
      >
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          <Trans>Detail příkazu</Trans>
        </h1>
        {order.status === "pending" ? <Spinner className="m-auto" /> : null}
        {order.status === "error" ? <Failed error={order.error} /> : null}
        {order.status === "success" ? (
          <>
            <OfferPropertiesList
              properties={[
                {
                  label: <Trans>Možný prodej celkem za</Trans>,
                  value: order.data.order.totalPrice,
                },
                {
                  label: <Trans>Komodita</Trans>,
                  value: order.data.crop.name,
                },
                {
                  label: <Trans>Farma</Trans>,
                  value: order.data.company.name,
                },
                {
                  label: <Trans>Sklad</Trans>,
                  value: order.data.storage.name,
                },
              ]}
            />
            <Spacer className="h-6" />
            <OrderForm
              initialValues={{
                quarter: order.data.quarter.id,
                tons: order.data.order.amount,
                note: order.data.order.note,
                pricePerTon: order.data.order.unitPrice,
                lowPricePerTon: order.data.order.unitLowPrice,
                hasLowPrice: !!order.data.order.unitLowPrice,
                customTransport: order.data.order.customTransport,
                customTransportNote: order.data.order.customTransportNote,
              }}
              key={orderId}
              onSubmit={guardedSubmit}
            >
              <OrderForm.QuarterField />
              <OrderForm.LowPriceField currency={order.data.order.currency} />
              <OrderForm.PriceField currency={order.data.order.currency} />
              <OrderForm.CompanyField />
              <OrderForm.TraderField />
              <OrderForm.AmountField />
              <OrderForm.CustomTransportField />
              <OrderForm.NoteField />
            </OrderForm>
            <Button.Container>
              <Button.Delete
                type="button"
                onClick={() => openConfirmationDialog(ConfirmationDialog)}
              >
                <Trans>Smazat příkaz</Trans>
              </Button.Delete>
              <Button
                disabled={editOrder.status === "pending"}
                form={OrderForm.formId}
                type="submit"
                variant="primary"
              >
                <Trans>Uložit</Trans>
              </Button>
            </Button.Container>
          </>
        ) : null}
      </FullscreenModalLayout>
      <Outlet />
    </>
  );
};

export { Order, path };
