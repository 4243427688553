import { logger } from "@/common/services/logger";
import { t } from "@lingui/macro";
import { z } from "zod";

const graphqlErrorSchema = z.object({
  response: z.object({
    errors: z.array(
      z.object({
        extensions: z.object({
          validation: z.record(
            z.string(),
            z.array(
              z.object({
                message: z.string(),
              }),
            ),
          ),
        }),
      }),
    ),
  }),
});

// FIXME what sort of errors we can actually get from the graphql backend?
const graphql2ErrorSchema = z.object({
  response: z.object({
    errors: z.array(
      z.object({
        message: z.string(),
      }),
    ),
  }),
});

const handleFormError = (e: Error): Errors => {
  const errors: { field: string; error: string[] }[] = [];
  const graphqlError = graphqlErrorSchema.safeParse(e);
  const graphql2Error = graphql2ErrorSchema.safeParse(e);

  if (graphqlError.success) {
    graphqlError.data.response.errors.forEach((error) => {
      const validation = error.extensions.validation;
      Object.keys(validation).forEach((key) => {
        errors.push({
          field: key,
          error: validation[key].map((x) => x.message),
        });
      });
    });

    return errors;
  }

  if (graphql2Error.success) {
    errors.push({
      field: "root",
      error: graphql2Error.data.response.errors.map((e) => e.message),
    });
    return errors;
  }

  logger.error(graphqlError.error);
  errors.push({
    field: "root",
    error: [t`Nastala neočekávaná chyba`],
  });

  return errors;
};

type Errors = Array<{
  error: string[];
  field: string;
}>;

export { handleFormError };
export type { Errors };
